import { Injectable } from '@angular/core';
import * as copyToClipboard from 'copy-to-clipboard';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CopyToClipboardService {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  copy(strToCopy: string, e?: any, translationKey?: string): void {
    copyToClipboard(strToCopy);
    if (e) {
      e.stopPropagation();
    }
    if (translationKey) {
      this.toastr.success(this.translate.instant(translationKey));
    }
  }
}
