import { OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { filter } from 'rxjs/operators';

import * as fromRoot from './shared/redux/reducers';

export class OrgContextComponent implements OnDestroy {
  orgId$: Observable<string>;
  orgIdSub: Subscription;

  constructor(private s: Store<fromRoot.State>) {
    // Any subclass can subscribe to this observable or some piped version of it
    this.orgId$ = s.pipe(select(fromRoot.getCurrOrgId), filter((id) => !!id));
  }

  ngOnDestroy(): void {
    if (!!this.orgIdSub) {
      this.orgIdSub.unsubscribe();
    }
  }
}
